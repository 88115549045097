#burnBgContent b {
	margin: 5px 5px;
}
#topNav {
	width: 80%;
	margin: auto;
}
.page {
	width: 80%;
	margin: auto;
}
a {
	text-decoration: none;
}
@font-face {
	font-family: "Akira";
	font-style: normal;
	font-weight: 500;
	src: url("assets/fonts/Akira.otf") format("opentype");
}

@font-face {
	font-family: "Avenir";
	font-style: normal;
	font-weight: 500;
	src: url("assets/fonts/Avenir.otf") format("opentype");
}

@font-face {
	font-family: "Roman";
	font-style: normal;
	font-weight: 500;
	src: url("assets/fonts/Roman.ttf") format("truetype");
}

#burnBgSection {
	background-repeat: no-repeat !important;
	background-size: 100% 100% !important;
	position: relative;
}
// #burnBgContent.m {
// 	padding-top: 250px;
// }
#burnBg {
	width: 100%;
	height: 100%;
}
#burnBgSection h1 {
	font-family: "Akira";
	font-size: 48px;
	margin-bottom: 20px;
}
#burnBgSection h1 span {
	font-family: "Roman";
}
#homeBgSection {
	width: 100%;
	height: 100%;

	position: relative;
}
#homeNotice b{
    display: block;
}
#homeBgContent {
	position: absolute;
	top: 55%;
	width: 70%;
	left: 50%;
	transform: translate(-50%, -50%);
}
#homeCenter .vector {
	width: 55px;
	margin-top: -100px;
}
#homeOptionSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 70%;
	margin: auto;
}
.home-button {
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 100px;
}
.home-button.b {
	background: black;
	color: white;
	border-radius: 50px;
	position: relative;
	padding-left: 30px;
}
.home-button.w {
	background: white;
	color: black;
	border-radius: 50px;
	position: relative;
	padding-left: 30px;
}
.home-button p {
	max-width: 80%;
}
.home-button img {
	position: absolute;
	top: -5px;
	left: -5px;
	width: 50px !important;
}
.home-option {
	flex: 1;
	margin: 0 15px;
	margin-top: 40px;
	transition: 0.5s all;
}
#homeOptionSection:hover .home-option {
	opacity: 0.5;
}
.home-option:hover {
	opacity: 1 !important;
	transform: scale(1.05);
}
.home-option img {
	width: 100%;
}
#homeBg {
    width: 120%;
    height: 120%;
    margin-left: -10%;
}
#homeBgSection h1 {
	font-family: "Akira";
	font-size: 48px;
}
#homeBgSection h1 span {
	font-family: "Roman";
}
#mintedBoxContent {
	font-family: "Akira";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid black;
	border-radius: 100%;
	width: 110px;
	height: 110px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 100;
}
#mintedBoxContent h2 {
	font-size: 24px;
	margin: 0;
}
#mintedBoxContent p {
	font-size: 8px;
	margin: 0;
}
#mintedSection {
	display: flex;
	width: 70%;
	margin: auto;
	justify-content: center;
	align-items: center;
	margin-top: 200px;
	padding-bottom: 150px !important;
}
#homeBgContent #burnBgContent{
    font-weight: bold;
}
#homeBgContent #burnBgContent span{
    font-weight: 300;
}
#homeBgContent #burnBgContent{
    margin-top: -60px;
}
#mintedBox {
	width: 500px;
	position: relative;
}
#mintedBox img {
	width: 100%;
}
#mintedContent {
	margin-left: 50px;
	text-align: left;
	color: white;
}
#mintedContent p {
	margin-bottom: 30px;
	font-family: "Avenir";
	font-size: 13px;
}
#mintedContent h1 {
	font-family: "Akira";
	font-size: 36px;
}
#burnBgContent p {
	display: flex;
	align-items: center;
	justify-content: center;
}
#burnBgContent p img {
	width: 50px;
	margin-right: 15px;
}
.btn {
	border: 1px solid white;
	color: white;
	background: transparent;
	border-radius: 35px;
	padding: 10px 25px;
	transition: 0.4s all;
}
.btn:hover {
	background-color: white;
	color: black;
}

.topLogo {
	width: 120px;
	transition: 0.6s all ease-in-out;
}
.topLogo:hover {
	opacity: 0.8;
	transform: rotate(720deg);
}
#topBtn {
	border-radius: 30px;
	font-size: 13px;
	height: 40px;
	margin: auto;
	color: whitesmoke;
	border-color: whitesmoke;
	margin-left: 35px;
	cursor: pointer;
}

.page #signInText {
	color: black;
}

.page #topBtn {
	color: black;
	border-color: black;
	margin-left: 0;
}

#topBtn.signoutBtn {
	padding: 6px;
	transition: 0.4s all;
	padding-left: 15px;
}
#topBtn.signoutBtn:hover {
	opacity: 0.7;
	cursor: pointer;
}
#topBtn h3 {
	display: none;
}
#topBtn.signoutBtn h3 {
	display: block;
}
#topBtn.signoutBtn label {
	background: white;
	border-radius: 30px;
	color: black;
	font-size: 12px;
	padding: 3px 15px;
	text-transform: none;
	cursor: pointer;
}
#topBtn h3 {
	font-size: 12px;
	text-transform: none;
	margin-right: 10px;
}
.top-link img {
	width: 25px;
	margin-left: 35px;
}
.top-link {
	display: flex;
	align-items: center;
	justify-content: center;
}
.top-link:hover {
	opacity: 0.7;
}
.App {
	text-align: center;
	background-color: #202020;
	margin: auto;
	margin-top: 3%;
}
body {
	background: #202020;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

#burnItemSection {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
.burnItem {
	flex: 0 0 15%;
	margin: 20px;
}
.burnItem button {
	background-color: black;
	color: white;
	border-radius: 30px;
	transition: 0.6s;
	padding: 8px 15px;
}
.burnItem button:hover {
	opacity: 0.7;
}
.burnItem img {
	width: 100%;
}
.burnItem.r button {
	position: relative;
	background-color: #e60000;
	border: none;
	width: 100%;
	margin-top: 15px;
	padding-left: 15%;
}
.burnItem.g button {
	margin-top: 15px;
	position: relative;
	background-color: #f1b600;
	border: none;
	padding-left: 15%;
	width: 100%;
}
.burnItem.r button img,
.burnItem.g button img {
	position: absolute;
	width: 40px;
	left: -5px;
	top: -5px;
	bottom: 0;
}
.modal-btn p {
	font-size: 12px !important;
	max-width: 80%;
	margin: auto;
	margin-top: 20px;
}
#footer {
	width: 80%;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
#footer h3 {
	font-family: "Akira";
	font-size: 18px;
	color: white;
}
#footer div {
	display: flex;
}
#footer h3 span {
	font-family: "Roman";
}
#footer .btm-link {
	margin-left: 25px;
}
#footer .btm-link img {
	width: 25px;
}

.prompt-modal {
	text-align: center;
}
.prompt-modal h3 {
	font-family: "Akira";
	font-size: 18x;
}
.prompt-modal h3 span {
	font-family: "Roman";
}
.prompt-modal .no-btn {
	padding: 6px 20px;
	border: 2px solid black;
	background-color: transparent;
	border-radius: 30px;
	font-family: "Akira";
	font-size: 14px;
	margin-right: 5px;
}

.prompt-modal .yes-btn {
	padding: 6px 20px;
	border: 2px solid #eead00;
	background-color: #eead00;
	color: white;
	border-radius: 30px;
	font-family: "Akira";
	font-size: 14px;
	margin-left: 5px;
}

.callback-modal .modal-box {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	height: 300px;
	outline: none !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.prompt-modal .modal-box {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	height: 400px;
	outline: none !important;
	display: flex;
	justify-content: center;
	align-items: center;
}
.modal-close {
	position: absolute;
	top: 0;
	right: 0;
	background: transparent;
	border: none;
	font-size: 20px;
}
button,
a {
	cursor: pointer;
}
.modal-bg {
	width: 600px;
	position: absolute;
	z-index: -1;
	left: 50%;
	top: 50%;
	height: 400px;
	transform: translate(-50%, -50%);
}
.callback-modal.lg .modal-box {
	height: 480px;
	width: 500px;
}
.callback-modal.lg h1 {
	font-family: "Akira";
	font-size: 24px;
	text-transform: uppercase;
}
.callback-modal.lg h1 span {
	font-family: "Roman";
}
.callback-modal.xl .modal-box {
	height: 480px;
	width: 900px;
}
.callback-modal.xl h1 {
	font-family: "Akira";
	font-size: 24px;
	text-transform: uppercase;
}
.callback-modal.xl h1 span {
	font-family: "Roman";
}

.callback-modal.lg .modal-bg {
	width: 800px;
	position: absolute;
	z-index: -1;
	left: 50%;
	top: 50%;
	height: 600px;
	transform: translate(-50%, -50%);
}
.callback-modal.lg img {
	width: 40%;
}
.callback-modal.xl .modal-bg {
	width: 1200px;
	position: absolute;
	z-index: -1;
	left: 50%;
	top: 50%;
	height: 700px;
	transform: translate(-50%, -50%);
}
.callback-modal.xl img {
	width: 70%;
}

.minted-section {
	display: flex;
	justify-content: center;
}
.minted-section .vector {
	width: 40px !important;
}
.minted-section h3 {
	font-family: "Akira";
	font-size: 16px;
	text-transform: uppercase;
}

.callback-modal.lg p,
.callback-modal.xl p {
	font-size: 12px;
	font-weight: bold;
	width: 70%;
	margin: auto;
	margin-top: 3em;
	margin-bottom: 1em;
	// margin-bottom: 10px;
	max-width: 400px;
}

.twitter-btn {
	background-color: #00acee;
	border-radius: 30px;
	border: none;
	color: white;
	font-size: 12px;
	font-weight: 700;
	display: flex;
	align-items: center;
	padding: 5px 15px;
	margin: auto;
	margin-top: 10px !important;
}
.twitter-btn img {
	margin-right: 8px;
	width: 20px !important;
}

.prompt-modal .modal-box {
	flex-direction: column;
	height: 300px;
}

#mintPromptSection {
	position: relative;
}

#mintPromptSection img {
	width: 100px !important;
	margin: 0 10px;
}
.hide {
	display: none;
}

.mint-count {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid black;
	font-weight: 900;
	border-radius: 100%;
	font-size: 18px;
	background-color: transparent;
}
.minus {
	position: absolute;
	top: 50%;
	left: -40px;
	transform: translate(-50%, -50%);
}
.plus {
	position: absolute;
	top: 50%;
	right: -80px;
	transform: translate(-50%, -50%);
}

#homeCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

#topLogoSection b {
	color: white;
	font-weight: 300;
	margin: 15px;
}
#topLogoSection {
	display: flex;
	justify-content: center;
	align-items: center;
}
.topLogo {
	width: 80px;
}

@media (max-width: 1300px) {
	#homeCenter .vector {
		width: 45px;
	}
	#homeOptionSection {
		width: 85%;
	}
	.home-button p {
		font-size: 13px;
	}
	.burnItem {
		flex: 0 0 19%;
	}
	.burnItem button {
		font-size: 12px;
	}
}

@media (max-width: 960px) {
	#homeCenter .vector {
		display: none;
	}
	.burnItem {
		flex: 0 0 26%;
	}
	.topLogo {
		width: 50px;
	}
}
@media (max-width: 850px) {
	.page {
		width: 90%;
	}
	#topNav {
		width: 90%;
	}
	.topLogo {
		width: 40px;
	}
	#homeOptionSection {
		width: 100%;
	}
	.top-link img {
		width: 20px;
	}
	#topBtn h3,
	#topBtn.signoutBtn label {
		font-size: 10px;
	}
	.burnItem button {
		font-size: 11px;
	}
}

@media (max-width: 650px) {
	.burnItem {
		flex: 0 0 40%;
	}
	#mintPromptSection img {
		width: 60px !important;
	}
	.mint-count {
		width: 30px;
		height: 30px;
	}
}

@media (max-width: 560px) {
    #mintedSection{
        margin-top: 0;
    }
    #homeBgContent #burnBgContent {
        margin-top: 50px ;
    }
    #homeBgContent #burnBgContent p{
        color: white !important;
    }
	#mintedSection,
	#homeOptionSection {
		flex-direction: column;
	}
	.home-option > img {
		display: none;
	}
	.home-option {
		width: 90%;
	}
	#topBtn h3 {
		display: none !important;
	}
	#topBtn {
		border: none;
		margin-left: 5px;
	}

	.page #topBtn {
		color: whitesmoke;
		border-color: whitesmoke;
		border: solid 1px;
		margin-left: 0;
	}

	.page #signInText {
		color: whitesmoke;
	}

	.home-button {
		margin-bottom: 5px;
		margin-top: 5px;
	}
	#mintedBox img {
		width: 60%;
	}
	#mintedContent {
		text-align: center;
		margin: 0;
	}
	#homeBgSection h1 {
		font-size: 32px;
	}
	.home-option {
		margin-top: 25px;
	}
	.burnItem {
		flex: 0 0 35%;
	}
	#burnBgSection {
		background: none !important;
	}

	#burnBgSection h1,
	#burnBgContent p {
		color: white;
	}
	#mintPromptSection img {
		width: 50px !important;
	}
	.mint-count {
		width: 25px;
		height: 25px;
	}
	.prompt-modal .modal-box {
		width: 350px;
	}
	.callback-modal .modal-box,
	.callback-modal.lg .modal-box {
		width: 350px;
	}
	.minted-section .vector {
		display: none;
	}
	.callback-modal.xl img {
		width: 50px;
	}
	.minted-section h3 {
		font-size: 10px;
	}
	.mid {
		margin-right: 8px;
	}
	.minted-section {
		margin-bottom: 20px;
	}
    #homeBg{
        display: none;
    }
    #homeBgContent{
        position: relative;
        transform: none;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        margin-top: 15px;
        color: white;
    }
}

@media (max-width: 400px) {
	.burnItem button {
		width: 100%;
		margin-top: 15px;
	}
	.burnItem {
		flex: 0 0 100%;
	}
	.topLogo {
		width: 40px;
	}
	#homeBgSection h1 {
		font-size: 28px;
	}
	.home-option {
		margin-top: 15px;
	}
	#homeBgSection {
		height: 60%;
	}
	#mintedBox img {
		width: 40%;
	}
}
